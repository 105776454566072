





































































































































































import { AdvancedSelect, AlertBanner, ConfirmModal, Scrollbar } from '@/app/components';
import { useAxios, useFacetsFilters } from '@/app/composable';
import store from '@/app/store';
import { maxLengthValidator, minLengthValidator, regexValidator, requiredValidator } from '@/app/validators';
import { ApolloAPI } from '@/modules/apollo/api';
import { AssetsAPI } from '@/modules/asset/api';
import { AssetTypeId, StatusCode } from '@/modules/asset/constants';
import { WorkflowAPI } from '@/modules/workflow-designer/api';
import {
    useDatasetAssetParameter,
    usePipelineSearch,
    useResultAssetParameter,
} from '@/modules/workflow-designer/composable';
import { ExecutionStatus } from '@/modules/workflow-designer/constants';
import { CheckIcon, XIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { AlertsAPI } from '../api';
import { AlertEvents } from '../components';
import { useAlerts } from '../composable';
import { AlertSourceType } from '../constants';
import { Alert, AlertEntity, AlertEvent } from '../interfaces';

extend('required', requiredValidator);
extend('max', maxLengthValidator);
extend('min', minLengthValidator);
extend('regex', {
    ...regexValidator,
    message: 'Title must contain only alphanumeric characters, dashes, underscores, spaces and at least one letter.',
});

export default defineComponent({
    name: 'ConfigureAlert',
    props: {
        id: {
            type: String,
            required: false,
        },
        backTo: {
            type: String,
            default: 'alerts',
        },
    },
    metaInfo() {
        return {
            title: `${(this as any).actionText} Alert`,
        };
    },
    components: {
        Scrollbar,
        ConfirmModal,
        OrbitSpinner,
        XIcon,
        CheckIcon,
        ValidationObserver,
        ValidationProvider,
        AdvancedSelect,
        AlertEvents,
        AlertBanner,
    },
    setup(props, { root }) {
        const alertRef = ref<any>(null);
        const alertEvents = ref<any>(null);
        const actionText = computed(() => (props.id ? 'Update' : 'Create'));
        const { exec, loading } = useAxios(true);
        const loadingAlert = ref<boolean>(false);
        const editingMode = ref<boolean>(false);
        const { getEntityTypeFromSourceType, save, fetchAlerts, loading: fetchOrSaveLoading } = useAlerts(root);
        const { capitalize } = useFacetsFilters();
        const pageLoading = computed(() => fetchOrSaveLoading.value || loading.value || loadingAlert.value);
        const alertEntities = ref<AlertEntity[]>([]);
        const initialAlertConfiguration = ref<Alert | null>(null);
        const showChangeSourceTypeModal = ref<boolean>(false);
        const newSourceType = ref<AlertSourceType | null>(null);
        const showChangeEntityIdModal = ref<boolean>(false);
        const newEntityId = ref<string | null>(null);

        const alert = ref<Alert>({
            name: '',
            entityType: null,
            entityId: null,
            sourceType: null,
            events: [],
        });

        const sourceOptions = [
            { title: 'Data Check-in Pipeline', value: AlertSourceType.DataCheckin },
            { title: 'Analytics Pipeline', value: AlertSourceType.Analytics },
            { title: 'Dataset', value: AlertSourceType.Dataset },
            { title: 'Result', value: AlertSourceType.Result },
        ];

        const excludedIds = computed(() =>
            alert.value.sourceType
                ? store.getters.alertEngine.getExcludedIds(alert.value.sourceType, alert.value.id)
                : [],
        );

        const sourceTitle = (value: string) => sourceOptions.find((option) => option.value === value)?.title ?? '';

        const pipelineType = computed(() => alert.value.sourceType ?? '');
        const entityId = computed(() => alert.value.entityId as string);
        const noOfItems = ref<number>(30);

        const { pipelines, refetch: refetchPipelines, totalItems: totalPipelines } = usePipelineSearch(
            root,
            pipelineType,
            noOfItems,
            entityId,
            excludedIds,
            Object.values(ExecutionStatus).filter((status) => status !== ExecutionStatus.Configuration),
        );

        const { assets: datasets, refetch: refetchDatasets, totalItems: totalDatasets } = useDatasetAssetParameter(
            null,
            root,
            noOfItems,
            entityId,
            excludedIds,
            [StatusCode.Available],
        );

        const { assets: results, refetch: refetchResults, totalItems: totalResults } = useResultAssetParameter(
            null,
            root,
            noOfItems,
            entityId,
            excludedIds,
            [StatusCode.Available],
        );

        const closeModals = () => {
            showChangeSourceTypeModal.value = false;
            showChangeEntityIdModal.value = false;
            newSourceType.value = null;
            newEntityId.value = null;
        };

        const handleSourceChange = async () => {
            alert.value.sourceType = newSourceType.value;
            alert.value.entityId = null;
            closeModals();
            alert.value.entityType = getEntityTypeFromSourceType(alert.value.sourceType);
            if (alertEvents.value && editingMode.value) alertEvents.value.cancelAlertEvent();
            alert.value.events = [];
            await refetch('', 1);
        };

        const confirmSourceChange = (value: AlertSourceType) => {
            if (value === alert.value.sourceType) return;
            newSourceType.value = value;
            if (!alert.value.events.length) {
                return handleSourceChange();
            } else {
                showChangeSourceTypeModal.value = true;
            }
        };

        const cancel = async () => {
            if (props.backTo === 'assets')
                (root as any).$router.push({ name: props.backTo, query: store.state.queryParams.assets });
            else if (props.backTo === 'data-checkin-jobs')
                (root as any).$router.push({ name: props.backTo, query: store.state.queryParams.jobs });
            else if (props.backTo === 'workflows')
                (root as any).$router.push({ name: props.backTo, query: store.state.queryParams.workflows });
            else (root as any).$router.push({ name: 'alerts', query: { tab: 1 } });
        };

        const saveChanges = async () => {
            const valid = await alertRef.value.validate();
            if (valid) {
                const payload = R.clone(alert.value);
                payload.entityId = String(payload.entityId);
                payload.events.forEach((event: any) => {
                    event.entityId = String(event.entityId);
                });
                save(payload);
            }
        };

        const refetch = async (text: string, page: number) => {
            switch (alert.value.sourceType) {
                case AlertSourceType.DataCheckin:
                case AlertSourceType.Analytics:
                    await refetchPipelines(text, page);
                    break;
                case AlertSourceType.Dataset:
                    await refetchDatasets(text, page);
                    break;
                case AlertSourceType.Result:
                    await refetchResults(text, page);
                    break;
                default:
                // do nothing
            }
        };

        const itemsInfo = computed(() => {
            switch (alert.value.sourceType) {
                case AlertSourceType.DataCheckin:
                case AlertSourceType.Analytics:
                    return { items: pipelines.value, totalItems: totalPipelines.value };
                case AlertSourceType.Dataset:
                    return { items: datasets.value, totalItems: totalDatasets.value };
                case AlertSourceType.Result:
                    return { items: results.value, totalItems: totalResults.value };
                default:
                    return { items: [], totalItems: 0 };
            }
        });

        const selectedEntity = computed(
            () => itemsInfo.value.items.find((item: any) => item.id === alert.value.entityId) ?? null,
        );

        const problematicEntityMessage = computed(() => {
            if (selectedEntity.value?.executionStatus === ExecutionStatus.Updating)
                return `The ${sourceTitle(alert.value.sourceType as string)} you have selected is still in ${capitalize(
                    selectedEntity.value.executionStatus,
                )} status. So no alerts shall be triggered until it has been finalised.`;
            else if (selectedEntity.value?.status === StatusCode.Uploading)
                return `The ${sourceTitle(alert.value.sourceType as string)} you have selected is in ${capitalize(
                    selectedEntity.value.status,
                )} status. So no alerts shall be triggered until its parent pipeline is finalised and has been executed once.`;
            else if (selectedEntity.value?.executionStatus === ExecutionStatus.Suspended)
                return `The ${sourceTitle(alert.value.sourceType as string)} you have selected is in ${capitalize(
                    selectedEntity.value.executionStatus,
                )} status. So no alerts shall be triggered until it is activated again.`;
            return null;
        });

        const resetEntitiesAndEvents = (resetEvents: boolean) => {
            if (alertEvents.value && editingMode.value) alertEvents.value.cancelAlertEvent();
            if (resetEvents) alert.value.events = [];
            alertEntities.value = [];
        };

        const getAnalyticsTasks = (id: string) => {
            exec(WorkflowAPI.getTasks(id)).then((res: any) => {
                alertEntities.value = alertEntities.value.concat(
                    res.data.map((task: any) => ({
                        id: task.id,
                        name: task.displayName,
                        type: 'task',
                    })),
                );
            });
        };

        const handleDataCheckinChange = (value: string) => {
            // Find selected data checkin pipeline and add it to alert entities
            const dataCheckinPipeline = pipelines.value.find((pipeline: any) => pipeline.id === value);
            alertEntities.value.push({
                id: dataCheckinPipeline.id,
                name: dataCheckinPipeline.name,
                type: AlertSourceType.DataCheckin,
            });
            // Find (exluded) datasets used in other alerts
            const excludedDatasetIds = store.getters.alertEngine.getExcludedIds(
                AlertSourceType.Dataset,
                alert.value.id,
            );
            // Retrieve pipeline's datasets (to be used in nested alert events)
            for (let i = 0; i < dataCheckinPipeline.provenanceAssetIds.length; i++) {
                const assetId = dataCheckinPipeline.provenanceAssetIds[i];
                if (assetId && !excludedDatasetIds.includes(assetId)) {
                    exec(AssetsAPI.getAsset(assetId)).then((res) => {
                        const asset = res?.data;
                        if (asset.status === StatusCode.Available) {
                            alertEntities.value.push({
                                id: asset.id,
                                name: asset.name,
                                type: AlertSourceType.Dataset,
                            });
                        }
                    });
                }
            }
        };

        const handleAnalyticsChange = (value: string) => {
            // Find selected analytics pipeline and add it to alert entities
            const analyticsPipeline = pipelines.value.find((pipeline: any) => pipeline.id === value);
            alertEntities.value.push({
                id: analyticsPipeline.id,
                name: analyticsPipeline.name,
                type: AlertSourceType.Analytics,
            });
            // Find (exluded) results used in other alerts and retrieve pipeline's tasks
            if (analyticsPipeline?.id) {
                getAnalyticsTasks(analyticsPipeline.id);
                const excludedResultIds = store.getters.alertEngine.getExcludedIds(
                    AlertSourceType.Result,
                    alert.value.id,
                );
                exec(WorkflowAPI.getProvenanceAssets(analyticsPipeline.id)).then((res: any) => {
                    // Find already used result ids
                    const usedResultIds = alert.value.events
                        .filter((event) => event.sourceType === AlertSourceType.Result)
                        .map((event) => String(event.entityId));
                    const resultAssets = res.data.filter(
                        (asset: any) =>
                            (asset.assetTypeId === AssetTypeId.Result &&
                                asset.status === StatusCode.Available &&
                                !excludedResultIds.includes(asset.id)) ||
                            usedResultIds.includes(String(asset.id)),
                    );
                    alertEntities.value = alertEntities.value.concat(
                        resultAssets.map((result: any) => ({
                            id: result.id,
                            name: result.name,
                            type: AlertSourceType.Result,
                        })),
                    );
                });
            }
        };

        const handleDatasetChange = (value: string) => {
            // Find selected dataset and add it to alert entities
            const dataset = datasets.value.find((asset: any) => asset.id === value);
            alertEntities.value.push({
                id: dataset.id,
                name: dataset.name,
                type: AlertSourceType.Dataset,
            });
            // Find (exluded) data checkin pipeline used in other alerts
            const excludedDataCheckinIds = store.getters.alertEngine.getExcludedIds(
                AlertSourceType.DataCheckin,
                alert.value.id,
            );

            for (const provenanceAssetWorkflowId of dataset.provenanceAssetWorkflowIds) {
                // Retrieve dataset's pipeline (to be used in nested alert events)
                if (!excludedDataCheckinIds.includes(provenanceAssetWorkflowId))
                    exec(ApolloAPI.get(provenanceAssetWorkflowId)).then((res) => {
                        alertEntities.value.push({
                            id: res?.data?.id,
                            name: res?.data?.name,
                            type: AlertSourceType.DataCheckin,
                        });
                    });
            }
        };

        const handleResultChange = async (value: string) => {
            // Find selected result and add it to alert entities
            let result = results.value.find((asset: any) => asset.id === value);
            if (!result) {
                const res = await exec(AssetsAPI.getAsset(Number(value)));
                if (res?.data.status === StatusCode.Uploading) {
                    result = res.data;
                    results.value = [{ ...result, id: String(result.id) }, ...results.value];
                }
            }
            alertEntities.value.push({
                id: result.id,
                name: result.name,
                type: AlertSourceType.Result,
            });
            // Find (exluded) analytics pipeline used in other alerts
            const excludedAnalyticsIds = store.getters.alertEngine.getExcludedIds(
                AlertSourceType.Analytics,
                alert.value.id,
            );
            for (const provenanceAssetWorkflowId of result.provenanceAssetWorkflowIds) {
                // Get result's pipeline and tasks (to be used in nested alert events)
                if (!excludedAnalyticsIds.includes(provenanceAssetWorkflowId))
                    exec(WorkflowAPI.get(provenanceAssetWorkflowId)).then((res) => {
                        alertEntities.value.push({
                            id: res?.data?.id,
                            name: res?.data?.name,
                            type: AlertSourceType.Analytics,
                        });
                        getAnalyticsTasks(provenanceAssetWorkflowId);
                    });
            }
        };

        const handleEntityIdChange = async (value: string, resetEvents = true) => {
            alert.value.entityId = value;
            await refetch('', 1);
            closeModals();
            resetEntitiesAndEvents(resetEvents);
            switch (alert.value.sourceType) {
                case AlertSourceType.DataCheckin:
                    return handleDataCheckinChange(value);
                case AlertSourceType.Analytics:
                    return handleAnalyticsChange(value);
                case AlertSourceType.Dataset:
                    return handleDatasetChange(value);
                case AlertSourceType.Result:
                    return handleResultChange(value);
                default:
                // do nothing
            }
        };

        const confirmEntityIdChange = (value: string) => {
            if (value === alert.value.entityId) return;
            newEntityId.value = value;
            if (!alert.value.events.length) {
                return handleEntityIdChange(value);
            } else {
                showChangeEntityIdModal.value = true;
            }
        };

        const initialize = async () => {
            fetchAlerts();
            if (props.id) {
                loadingAlert.value = true;
                exec(AlertsAPI.getAlert(props.id))
                    .then(async (res) => {
                        alert.value = res?.data;
                        initialAlertConfiguration.value = R.clone(alert.value);
                        handleEntityIdChange(alert.value.entityId as string, false);
                    })
                    .catch((e) => {
                        if (e.response.status === 404) {
                            (root as any).$toastr.e('The requested alert does not exist', 'Not Found');
                        } else if (e.response.status === 403) {
                            (root as any).$toastr.e('You do not have permission to view this alert', 'Not Authorised');
                        } else {
                            (root as any).$toastr.e(e.response.data.message);
                        }
                        cancel();
                    })
                    .finally(() => {
                        loadingAlert.value = false;
                    });
            }
        };

        const changeAlertEvent = (event: AlertEvent, index: number) => {
            alert.value.events.splice(index, 1, event);
        };

        const removeAlertEvent = (index: number) => {
            alert.value.events.splice(index, 1);
        };

        const hasChanges = computed(
            () => JSON.stringify(initialAlertConfiguration.value) !== JSON.stringify(alert.value),
        );

        const disableSaveOrUpdateBtn = computed(
            () => editingMode.value || !hasChanges.value || pageLoading.value || !alert.value.events.length,
        );

        const createOrUpdateBtnTooltip = computed(() => {
            if (editingMode.value)
                return `An alert event is being configured. Complete the configuration to ${
                    props.id ? 'Update' : 'Create'
                } the Alert.`;
            if (alert.value.events && !alert.value.events.length)
                return `At least one alert event is required to ${props.id ? 'Update' : 'Create'} the Alert.`;
            return '';
        });

        initialize();

        return {
            AlertSourceType,
            alertRef,
            alertEvents,
            alert,
            actionText,
            loadingAlert,
            pageLoading,
            disableSaveOrUpdateBtn,
            sourceOptions,
            cancel,
            saveChanges,
            sourceTitle,
            refetch,
            itemsInfo,
            noOfItems,
            handleSourceChange,
            editingMode,
            handleEntityIdChange,
            changeAlertEvent,
            removeAlertEvent,
            alertEntities,
            excludedIds,
            hasChanges,
            createOrUpdateBtnTooltip,
            confirmSourceChange,
            showChangeSourceTypeModal,
            confirmEntityIdChange,
            showChangeEntityIdModal,
            newEntityId,
            closeModals,
            selectedEntity,
            problematicEntityMessage,
        };
    },
});
